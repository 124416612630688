.titleContainer {
  padding: 0;
}

.border {
  border: 1px solid #c6c4c4;
  border-radius: 4px;
}

.content {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 30px;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  border-top-left-radius: 0;
}


.content section {
  flex-basis: 100%;
}

.content div {
  flex-basis: 25%;
  margin-right: 25px;
}

.tabHeader {
  background-color: #efefef;
  color: #555;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: -1px;
  user-select: none;
  cursor: pointer;
}

.borderActive {
  border-bottom: 1px solid #fff;
}

.tabHeader:hover {
  background-color: #33c3f0;
  color: #fff;
  cursor: pointer;
}

.tabHeader h2 {
  margin: 0;
  padding: 10px 15px;
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: 500;
}

.tabHeaderActive {
  background-color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #000;
  cursor: default;
}

.tab {
  display: none;
}

.tabActive {
  display: block;
}

.iconStyle {
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 5px;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  .content {
    border: none;
    padding: 20px;
    margin-bottom: 10px;
  }
  
  .tabHeader {
    border: none;
  }
}

